<template>
  <div :id="`${id}-app-axios-form-file`">
    <b-overlay :show="uploadInProgress">
      <b-form-file
        v-model="files"
        :placeholder="$t('action.file_upload')"
        :drop-placeholder="$t('action.drop_here')"
        v-bind="$attrs"
        :multiple="multiple"
        :directory="directory"
        :no-traverse="noTraverse"
        v-on="$listeners"
        @input="sendToServer"
      >
        <template slot="file-name" slot-scope="{ names }">
          <b-badge v-for="name in names" :key="name" variant="primary" class="ml-1">{{ name }}</b-badge>
        </template>
      </b-form-file>
    </b-overlay>
  </div>
</template>

<script>
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'

export default {
  name: 'AppAxiosFormFile',

  props: {
    id: {
      type: String,
      default: '',
    },

    multiple: {
      type: Boolean,
      default: true,
    },

    directory: {
      type: Boolean,
      default: false,
    },

    noTraverse: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      file: null,
      fileId: null,
      files: [],
      filesId: [],
      uploadInProgress: null,
    }
  },

  watch: {
    uploadInProgress: {
      handler(value) {
        this.$emit('upload-in-progress', value)
      },
    },
  },

  methods: {
    sendToServer(files) {
      this.uploadInProgress = true
      files.forEach((file, index, files) => {
        const formData = new FormData()
        formData.append('temporary_file', file)
        postFileRequest(formData, '', true)
          .then(response => {
            this.filesId.push(response.data.id)
          })
          .finally(() => {
            if (index === files.length - 1) {
              this.uploadInProgress = false
            }
          })
      })
      this.$emit('updateId', this.filesId)
    },
  },
}
</script>

<style lang="scss" scoped></style>
